import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    Button,
    Select,
    Box,
    TextField,
    OutlinedInput,
    ListItemText
} from '@mui/material';

import { setMessage } from '../actions/message';
import { renderLabel } from '../utils';
import userService from '../services/crudService';
import metaService from '../services/metaService';

const API_ENDPOINT = 'admin/user';

const userGroups = metaService.getGroups();
const allDepts = metaService.getDepartments();
const allSites = metaService.getSites();

export default function UserForm(props) {
    const { handleClose } = props;
    const mode = props?.data?.id ? 'update' : 'create';

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [siteDepts, setSiteDepts] = useState([]);

    const [user, setUser] = useState({
        name: props.data ? props.data.name : '',
        username: props.data ? props.data.username : '',
        email: props.data ? props.data.email : '',
        phone: props.data ? props.data.phone : '',
        photo: props.data ? props.data.photo : '',
        departments: props.data ? props.data.departments : [],
        groups: props.data ? props.data.groups : [],
        sites: props.data ? props.data.sites : []
    });

    const { name, username, email, phone, groups, sites, departments } = user;

    const handleOnSubmit = (ev) => {
        ev.preventDefault();

        const values = [name, username, email];

        const allFieldsFilled = values.every((field) => {
            const value = `${field}`.trim();
            return value !== '' && value !== '0';
        });

        if (!allFieldsFilled) {
            return dispatch(
                setMessage({
                    severity: 'error',
                    content: 'Please fill out all the required fields.'
                })
            );
        }

        if ('string' === typeof user.photo) {
            delete user.photo;
        }

        setLoading(true);

        if (mode === 'update') {
            return userService
                .update(API_ENDPOINT, props?.data?.id, user)
                .then((res) => {
                    setLoading(false);
                    handleClose();
                    dispatch(
                        setMessage({
                            content: 'User updated successfully',
                            data: res.data,
                            callback: () => window.location.reload()
                        })
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setMessage({
                            severity: 'error',
                            content: 'Something went wrong',
                            data: err.response.data
                        })
                    );
                });
        }

        return userService
            .create(API_ENDPOINT, user)
            .then((res) => {
                setLoading(false);
                handleClose();
                dispatch(
                    setMessage({
                        content: 'User created successfully',
                        data: res.data,
                        callback: () => window.location.reload()
                    })
                );
            })
            .catch((err) => {
                setLoading(false);
                dispatch(
                    setMessage({
                        severity: 'error',
                        content: 'Something went wrong',
                        data: err.response.data
                    })
                );
            });
    };

    const handleSites = (val) => {
        const filteredDepts = allDepts.filter((dept) => val.includes(dept.site));
        const filteredValue = filteredDepts
            .filter((dep) => departments.includes(dep.id))
            .map((d) => d.id);

        setSiteDepts(filteredDepts);
        handleInputChange({ target: { name: 'departments', value: filteredValue } });
    };

    const handleInputChange = (ev) => {
        const { name, value } = ev.target;
        switch (name) {
            case 'sites':
                handleSites(value);
                setUser((prevState) => ({
                    ...prevState,
                    [name]: typeof value === 'string' ? value.split(',') : value
                }));
                break;
            case 'departments':
                setUser((prevState) => ({
                    ...prevState,
                    [name]: typeof value === 'string' ? value.split(',') : value
                }));
                break;
            case 'groups':
                setUser((prevState) => ({
                    ...prevState,
                    [name]: [value]
                }));
                break;
            case 'photo':
                setUser((prevState) => ({
                    ...prevState,
                    [name]: ev.target.files[0]
                }));
                break;
            default:
                setUser((prevState) => ({
                    ...prevState,
                    [name]: value
                }));
        }
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <Box
                    component="form"
                    onSubmit={handleOnSubmit}
                    noValidate
                    encType="multipart/form-data">
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="Name"
                        autoFocus
                        value={name}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        value={username}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                    />
                    <TextField
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        type="tel"
                        autoComplete="phone"
                        value={phone}
                    />
                    {/* <FormControl fullWidth margin="normal">
                        <InputLabel id="photo">Photo</InputLabel>
                        <TextField
                            labelid="photo"
                            onChange={handleInputChange}
                            margin="normal"
                            fullWidth
                            id="photo"
                            label="Photo"
                            name="photo"
                            type="file"
                        />
                    </FormControl> */}
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="sites">Sites</InputLabel>
                        <Select
                            labelId="sites"
                            onChange={handleInputChange}
                            fullWidth
                            multiple
                            id="sites"
                            label="Sites"
                            name="sites"
                            value={sites}
                            input={<OutlinedInput id="select-multiple-sites" label="chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={renderLabel(allSites, value)} />
                                    ))}
                                </Box>
                            )}>
                            {allSites.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={sites.indexOf(id) > -1} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="departments">Departments</InputLabel>
                        <Select
                            labelId="departments"
                            onChange={handleInputChange}
                            fullWidth
                            multiple
                            id="departments"
                            label="Departments"
                            name="departments"
                            value={departments}
                            input={<OutlinedInput id="select-multiple-groups" label="chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={renderLabel(allDepts, value)} />
                                    ))}
                                </Box>
                            )}>
                            {siteDepts.map(({ id, name, site }) => (
                                <MenuItem key={id} value={id}>
                                    <Checkbox checked={departments.indexOf(id) > -1} />
                                    <ListItemText
                                        primary={`${name} — ${renderLabel(allSites, site)}`}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="groups">Group</InputLabel>
                        <Select
                            labelId="groups"
                            onChange={handleInputChange}
                            fullWidth
                            id="groups"
                            label="Groups"
                            name="groups"
                            value={groups}>
                            {userGroups.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        disabled={loading}
                        type="submit"
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {mode === 'update' ? 'Update User' : 'Create User'}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    );
}
