// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import ReactApexcharts from 'react-apexcharts';

// ** Icons Imports
import DotsVertical from 'mdi-material-ui/DotsVertical';

const MachinesOverview = () => {
    // ** Hook
    const theme = useTheme();

    const options = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false }
        },
        plotOptions: {
            bar: {
                borderRadius: 9,
                distributed: true,
                columnWidth: '40%',
                endingShape: 'rounded',
                startingShape: 'rounded'
            }
        },
        stroke: {
            width: 2,
            colors: [theme.palette.background.paper]
        },
        legend: { show: false },
        grid: {
            strokeDashArray: 7,
            padding: {
                top: -1,
                right: 0,
                left: -12,
                bottom: 5
            }
        },
        dataLabels: { enabled: false },
        colors: [
            theme.palette.background.default,
            theme.palette.background.default,
            theme.palette.background.default,
            theme.palette.primary.main,
            theme.palette.background.default,
            theme.palette.background.default
        ],
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        xaxis: {
            categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            tickPlacement: 'on',
            labels: { show: false },
            axisTicks: { show: false },
            axisBorder: { show: false }
        },
        yaxis: {
            show: true,
            tickAmount: 4,
            labels: {
                offsetX: -17,
                formatter: (value) => `${value > 999 ? `${(value / 1000).toFixed(0)}` : value}`
            }
        }
    };

    return (
        <Card>
            <CardHeader
                title="Machines Overview"
                titleTypographyProps={{
                    sx: { lineHeight: '2rem !important', letterSpacing: '0.15px !important' }
                }}
                action={
                    <IconButton
                        size="small"
                        aria-label="settings"
                        className="card-more-options"
                        sx={{ color: 'text.secondary' }}>
                        <DotsVertical />
                    </IconButton>
                }
            />
            <CardContent sx={{ '& .apexcharts-xcrosshairs.apexcharts-active': { opacity: 0 } }}>
                <ReactApexcharts
                    type="bar"
                    height={205}
                    options={options}
                    series={[{ data: [10, 15, 5, 19, 23] }]}
                />
                <Box sx={{ mb: 7, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" sx={{ mr: 4 }}>
                        12%
                    </Typography>
                    <Typography variant="body2">
                        Machine issues are 12% less compared to last month
                    </Typography>
                </Box>
                <Button fullWidth variant="contained">
                    Details
                </Button>
            </CardContent>
        </Card>
    );
};

export default MachinesOverview;
