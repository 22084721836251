
import { useState, useEffect } from 'react';
import metaService from 'src/services/metaService';

const allGroups = metaService.getSection('group') || [];
const userGroups = metaService.getSection('user')?.groups ?? [];
const userPermissions = getUserPermissions(userGroups, allGroups);

export const usePermissions = (requestedPermissions = []) => {
    const [permissions, setPermissions] = useState(false);

    useEffect(() => {
        setPermissions(userPermissions.some((p) => requestedPermissions.includes(p.codename)) || requestedPermissions.includes('*'));
    }, [requestedPermissions]);

    return permissions;
};

function getUserPermissions(userGroups, groups) {
    const permissions = [];
    const matchedGroups = groups.filter(group => userGroups.includes(group.id));

    for (let i = 0; i < matchedGroups.length; i++) {
        let gp = matchedGroups[i].permissions;
        if (gp.length) {
            permissions.push(...gp);
        }
    }

    return permissions;
};
